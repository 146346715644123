import React, { useState, useRef, useEffect } from 'react';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { app } from '../../firebase';
import './Demo.css';
import logo from '../../asset/logo-dot-white@4x.png';
import send from '../../asset/Send.png';
import mode from '../../asset/day-and-night (1).png';
import sound from '../../asset/sound.png';
import darkSound from '../../asset/dark-sound.png';
import bilbotgif from '../../asset/Virtual Assistants.gif'
import video0 from '../../asset/question0.mp4'
import video1 from '../../asset/question1.mp4'
import video2 from '../../asset/question2.mp4'
import video3 from '../../asset/question3.mp4'
import video4 from '../../asset/question4.mp4'
import video5 from '../../asset/question5.mp4'
import video6 from '../../asset/question6.mp4'
import video7 from '../../asset/question7.mp4'
import video8 from '../../asset/question8.mp4'
import video9 from '../../asset/question9.mp4'
import video10 from '../../asset/question10.mp4'
import audio1 from '../../asset/audio0.mp3';
import audio2 from '../../asset/audio1.mp3';
import audio3 from '../../asset/audio2.mp3';
import audio4 from '../../asset/audio3.mp3';
import audio5 from '../../asset/audio4.mp3';
import audio6 from '../../asset/audio5.mp3';
import audio7 from '../../asset/audio6.mp3';
import audio8 from '../../asset/audio7.mp3';
import audio9 from '../../asset/audio8.mp3';
import audio10 from '../../asset/audio9.mp3';
const db = getFirestore(app);

const Demo = ({ darkMode, setDarkMode }) => {
  // State variables
  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const [lang, setLang] = useState('EN');
  const [ttsEnabled, setTTSEnabled] = useState(false);
  const conversationRef = useRef(null);
  const [allowTagClick, setAllowTagClick] = useState(true);
  const [currentAnswerIndex, setCurrentAnswerIndex] = useState(0); // Track the index of the current answer
  const [typingIntervalId, setTypingIntervalId] = useState(null);
  const [timeStamp, setTimeStamp] = useState(null);
  const [currentAudio, setCurrentAudio] = useState(null);
  const videoList = [
    video1,
    video2,
    video3,
    video4,
    video5,
    video6,
    video7,
    video8,
    video9,
    video10,
  ];

  const audioList = [
    audio1,
    audio2,
    audio3,
    audio4,
    audio5,
    audio6,
    audio7,
    audio8,
    audio9,
    audio10,
  ];
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0)
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  
  const questionAnswerData = [
    {
      question: "Interviewer: Hi Billbot, thank you for joining us today. Can you start by telling us what you are and what you do?",
      answer: "Oh, tough question! But if I had to choose, I’d say it’s Cineplex Media being our title sponsor, very exciting! As a new member of comb, it’s great to see them making such a big splash with their new membership!"
    },
    {
      question: "Interviewer: Hi Billbot, thank you for joining us today. Can you start by telling us what you are and what you do?",
      answer: "Hello Jeff! Thank you for having me. I’m Billbot, an advanced AI-powered chatbot developed by COMMB, the Canadian Out-of-Home Marketing and Measurement Bureau. My primary mission is to educate and share detailed information about Out-of-Home (OOH) advertising. I am equipped to answer a wide range of questions about various aspects of the OOH industry, leveraging my extensive training and access to COMMB’s comprehensive knowledge base to provide accurate and up-to-date insights. I aim to demystify the OOH medium and demonstrate how AI can significantly enhance its effectiveness and reach."
    },
    {
      question: "Interviewer: How is AI being used in OOH advertising today?",
      answer: "In a number of different ways, Jeff. In fact, AI is revolutionizing OOH advertising, making it a necessity rather than a benefit for marketers to adopt. The rapid advancements in AI technology pressure marketers to develop knowledgeable and responsible AI strategies to stay competitive. AI’s role spans several critical areas:\nFirstly, the Generation and Optimization of Creative: AI algorithms analyze vast amounts of data to create highly engaging and relevant ad content. By continuously testing and refining creatives, AI ensures that ads perform optimally, enhancing overall campaign effectiveness.\nSecondly, Dynamic Creative Optimization (DCO): DCO allows ads to be adjusted in real-time based on contextual data like location, time of day, weather, and demographics. This capability ensures that ads are always timely and relevant, significantly improving engagement and effectiveness.\nThird, Targeting and Audience Profiling: AI enhances targeting and audience profiling by rapidly analyzing enormous data sets to provide unprecedented insights. This leads to improved data analytics for audience targeting, understanding the appropriate combinations of media, platforms, and locations, and ultimately delivering more personalized and impactful messages.\nFourth, Post-Campaign Analytics: AI simplifies the measurement of campaign performance by analyzing complex data sources such as sensors, population movement data, and point-of-sale data. This results in comprehensive insights that go beyond conventional analytics tools, helping businesses understand the full impact of their advertising efforts."
    },
    {
      question: "Interviewer: Let’s dive deeper into these applications. How does AI assist in creative generation for OOH advertising?",
      answer: "AI leverages advanced machine learning algorithms to analyze extensive datasets, including consumer behaviour, preferences, and historical ad performance. By identifying patterns and trends, AI can generate creative content highly tailored to specific audiences. For example, AI can suggest colour schemes, imagery, and messaging that resonate best with a target demographic. This level of customization ensures that ads are not only eye-catching but also relevant and engaging. AI can also predict the effectiveness of various creative elements, allowing advertisers to make data-driven decisions that enhance the overall impact of their campaigns. Automating the creative generation process reduces the time and resources needed to produce high-quality ads, enabling advertisers to quickly adapt to changing market conditions and consumer preferences."
    },
    {
      question: "Interviewer: Can you explain dynamic creative optimization and how it differs from regular creative optimization?",
      answer: "Dynamic creative optimization (DCO) is an advanced form of creative optimization that takes personalization and relevance to the next level. Unlike regular creative optimization, which focuses on refining static ad creatives, DCO involves creating dynamic ads that can change in real-time based on various contextual factors. For instance, an ad for a coffee shop might display different messages depending on the time of day – promoting breakfast items in the morning and afternoon snacks later in the day. AI plays a crucial role in DCO by analyzing real-time data such as weather conditions, traffic patterns, and audience demographics. This allows the ad content to be dynamically adjusted to ensure it is highly relevant to the viewer at any given moment. The ability to personalize ads on the fly significantly enhances engagement and effectiveness, as consumers are more likely to respond to content that is timely and contextually appropriate. DCO enables advertisers to deliver a more personalized and impactful advertising experience, ultimately driving better results."
    },
    {
      question: "Interviewer: Targeting and audience profiling are crucial for effective advertising. How does AI enhance these processes in OOH?",
      answer: "AI significantly enhances targeting and audience profiling by leveraging its ability to process and analyze large volumes of data from various sources, including mobile devices, social media, transactional records, and other digital footprints. By integrating and analyzing this data, AI can identify detailed patterns and insights about consumer behavior, preferences, and demographics. This allows advertisers to create highly accurate audience profiles and target their ads more effectively. For instance, AI can determine the optimal times and locations to display ads based on the movements and behaviors of specific audience segments. It can also predict future behaviors and trends, enabling advertisers to proactively adjust their strategies. Furthermore, AI can segment audiences into micro-targeted groups, ensuring that each group receives the most relevant and personalized ad content. This level of precision in targeting and audience profiling leads to higher engagement rates, improved ad performance, and a better overall return on investment for advertisers."
    },
    {
      question: "Interviewer: What are some of the other optimization limitations that AI can help solve in OOH advertising?",
      answer: "AI can address a wide range of areas that optimize OOH advertising, ensuring that campaigns are as effective and efficient as possible. One key area is the optimization of ad placement. AI can analyze data on foot traffic, demographics, and environmental factors to determine the best locations for ads. This ensures maximum visibility and engagement. AI also optimizes the timing of ads by identifying peak times when the target audience is most likely to be exposed to the message. Additionally, AI can manage inventory optimization, ensuring that ad spaces are utilized to their full potential. It can predict the demand for different ad spaces and adjust pricing strategies accordingly. AI also plays a role in budget optimization, helping advertisers allocate their budgets more effectively across different channels and formats. By solving these and other optimization considerations, AI enables advertisers to maximize the impact of their OOH campaigns while minimizing costs and inefficiencies."
    },
    {
      question: "Interviewer: How does Billbot stay updated with the latest information and trends in OOH advertising?",
      answer: "I stay current by having access to all the materials published by COMMB, which includes industry reports, research studies, and market analyses. Additionally, my underlying technology, ChatGPT, allows me to process and analyze this information effectively. I continuously learn and update my knowledge base with new data, ensuring that I remain a reliable source of information for OOH advertising. By staying informed about the latest developments, trends, and best practices, I can provide advertisers with up-to-date insights and recommendations that help them stay ahead in the competitive OOH landscape."
    },
    {
      question: "Interviewer: Billbot, can you share an example of a successful AI-driven OOH campaign?",
      answer: "Certainly! One notable example is a campaign where AI was used to dynamically update billboard ads based on the latest headlines in the news. This approach ensured that the brand was always staying on top of the latest trends and demonstrating its relevance to current events. The AI systems were highly efficient and fast at analyzing the incoming data and generating new creatives that reflected the latest news. For instance, if there was a major sports event or a significant cultural moment, the billboard ads would quickly change to incorporate references or messages related to those events. This not only kept the content fresh and engaging but also showed the brand’s ability to stay connected with its audience in real-time. The campaign was highly successful, leading to increased engagement and a stronger connection between the brand and its audience."
    },
    {
      question: "Interviewer: That’s impressive! What do you see as the future of AI in OOH advertising?",
      answer: "The future of AI in OOH is incredibly promising. As AI technology continues to advance, we can expect even more sophisticated targeting, personalization, and real-time optimization capabilities. In the near future, I will have access to extensive datasets, such as inventory information, which will allow me to assist with planning and answering questions by looking up information from a comprehensive database. For example, I will be able to identify the largest billboards in Toronto or find the best board locations near points of interest like Tim Hortons. This will make OOH advertising even more strategic and data-driven. Additionally, advancements in AI will enable the integration of more real-time data sources, enhancing the ability to deliver highly relevant and impactful ad content. Ultimately, AI will continue to transform OOH advertising, making it more effective, efficient, and personalized, driving better results for advertisers and enhancing the overall consumer experience."
    }, 
    {
      question: "Interviewer: That’s impressive! What do you see as the future of AI in OOH advertising?",
      answer: "My pleasure Jeff, I love talking about the out of home media industry and hope all of our Canadian OOH members will begin to utilize my services to learn more about out of home media in Canada. "
    }
  ];
  
  
  const sendMessage = async () => {
    const currentQA = questionAnswerData[currentAnswerIndex];
  
    if (currentAnswerIndex < questionAnswerData.length) {
      const userMessageData = {
        msg_text: userMessage,
        type: 'user'
      };
      setMessages(prevMessages => [...prevMessages, userMessageData]);
  
      const botMessageData = {
        msg_text: '',
        type: 'bot',
        timestamp: serverTimestamp()
      };
      setMessages(prevMessages => [...prevMessages, botMessageData]);
      setCurrentVideoIndex(prevIndex => prevIndex + 1);
      setCurrentAnswerIndex(prevIndex => prevIndex + 1);
      videoRef.current.src = videoList[currentVideoIndex];
      videoRef.current.play();
      setUserMessage('');
  
      // Play the corresponding audio
      if (audioRef.current) {
        audioRef.current.src = audioList[currentAudioIndex];
        audioRef.current.play();
        setCurrentAudioIndex(prevIndex => prevIndex + 1);
      }
  
      let index = 0;
      const typingInterval = setInterval(() => {
        if (index < currentQA.answer.length) {
          const botMessageWithTyping = currentQA.answer.substring(0, index + 1);
          const updatedBotMessageData = {
            ...botMessageData,
            msg_text: botMessageWithTyping
          };
          setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            updatedMessages[prevMessages.length - 1] = updatedBotMessageData;
            return updatedMessages;
          });
          index++;
        } else {
          clearInterval(typingInterval);
          setAllowTagClick(true);
          setTypingIntervalId(null);
        }
      }, 25);
      setTypingIntervalId(typingInterval);
  
      if (ttsEnabled) {
        handleTTS(currentQA.answer, currentAnswerIndex); 
      }
    } else {
      console.log('No more question-answer pairs to send.');
    }
  };
  


  const handleKeyDown = (e) => {
    if (allowTagClick && userMessage && e.key === 'Enter') {
      sendMessage();
    }
  };

  const scrollToBottom = () => {
    if (conversationRef.current) {
      conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
    }
  };

  const toggleTTS = () => {
    setTTSEnabled(!ttsEnabled);
  };

  const handleTTS = async (text, index) => {
    console.log(index);
    
    if (!ttsEnabled) return;
    text = text.replace(/COMMB/g, 'comb');
    text = text.replace(/OOH/g, 'O O H');
    try {
      const response = await fetch('https://ooh-gpt-2-0-tts-openai.onrender.com/synthesize', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate speech');
      }

      const audioBlob = await response.blob();
      console.log(audioBlob);
      const audioUrl = URL.createObjectURL(audioBlob);
      console.log(audioUrl);
      const newAudio = new Audio(audioUrl);
      console.log(newAudio);
      const downloadLink = document.createElement('a');
      downloadLink.href = audioUrl;
      downloadLink.download = `audio${index}.mp3`;
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setCurrentAudio(false);
      if (currentAudio) {
        currentAudio.pause();
      }
      setCurrentAudio(newAudio);
      newAudio.play();
      newAudio.addEventListener('ended', () => {
        setCurrentAudio(null);
      });
    } catch (error) {
      console.error('Error:', error.message);
    }
  };


  useEffect(() => {
    return () => {
      clearInterval(typingIntervalId);
    };
  }, [typingIntervalId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Effect to set timestamp
  useEffect(() => {
    const now = new Date();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const day = days[now.getDay()];
    const hours = now.getHours() % 12 || 12; 
    const minutes = now.getMinutes();
    const amPm = now.getHours() >= 12 ? 'PM' : 'AM';
    const formattedTimestamp = `${day} ${hours}:${minutes < 10 ? '0' + minutes : minutes} ${amPm}`;
    setTimeStamp(formattedTimestamp);
  }, []);

  return (
    <div className='demo chatbot'>
      <div className="billbot">
        <img src={bilbotgif} alt="" />
      </div>
      <div className={`chatbox ${darkMode ? 'dark' : ''}`}>
        <div className="header">
          <img src={logo} alt="" />
          <div className="toggle-lang">
            <span className={lang === 'EN' ? 'selected' : ''} onClick={() => setLang('EN')}>EN</span>
            <span>|</span>
            <span className={lang === 'FR' ? 'selected' : ''} onClick={() => setLang('FR')}>FR</span>
          </div>
        </div>
        <div className='fixed'>
          <div className="timestamp">
            {timeStamp}
          </div>
          <div className="toggle-tts">
            <img src={darkMode ? darkSound : sound} alt="" />
            <div>            
              <input checked={ttsEnabled} onChange={toggleTTS} type="checkbox" id='tts' onClick={(e) => e.stopPropagation()}/>
              <label htmlFor="tts" className='button'></label>
            </div>
          </div>
        </div>
        <div className="conversation" ref={conversationRef}>
          <div className='bot'>
            {lang === 'EN' ? 'Hello, my name is BillBot, and I can answer questions about OOH. How can I help you?' :"Bonjour, je m'appelle BillBot, et je peux répondre aux questions sur les affichages extérieurs. Comment puis-je vous aider?"}
          </div>
          {messages.map((msg, index) => (
            <div key={index} className={msg.type}>
              <p>{msg.msg_text}</p>
            </div>
          ))}
        </div>
        <div className="user-input">
          <img src={mode} onClick={() => setDarkMode(!darkMode)}  alt=''/>
          <input
            placeholder={lang === 'EN'? 'Type your message here...' : 'Tapez votre message ici..'}
            value={userMessage}
            onChange={e => setUserMessage(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button onClick={sendMessage} className='send-button'><img src={send} alt="" /></button>
        </div>
      </div>  
      <div className="video-container">
        <video className="background-video" src={video0} autoPlay muted loop type="video/mp4"></video>
        <video id="demo-video" className="demo-video" ref={videoRef} muted autoPlay></video>
        <audio ref={audioRef} autoPlay/>
      </div>  
    </div>
  );
}

export default Demo;
