import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import './App.css';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import ReactGA from 'react-ga4';
import Billbot from './component/chatbot/Billbot';
import Demo from './component/chatbot/Demo';
import Clean from './component/chatbot/Clean';
import searchGif from '../src/asset/gifs/LOAD.gif';
import successGif from '../src/asset/gifs/FOUND (1).gif';
import errorGif from '../src/asset/gifs/NOT FOUND.gif';
import defaultGif from '../src/asset/gifs/default.png';
import statusGif from '../src/asset/gifs/status (1).png';
import video from './asset/BILLBOT WEB enhanced.mp4'
import AdAiDemo from './component/chatbot/AdAiDemo';

// Initialize Google Analytics
const TRACKING_ID = "G-ST9BCR1WKQ";
ReactGA.initialize(TRACKING_ID);

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC3lfSzfqlwhoqAWHZH15w64c7H2kr7v74",
  authDomain: "ooh-gpt.firebaseapp.com",
  databaseURL: "https://ooh-gpt-default-rtdb.firebaseio.com",
  projectId: "ooh-gpt",
  storageBucket: "ooh-gpt.appspot.com",
  messagingSenderId: "831291528861",
  appId: "1:831291528861:web:8e32b6a6c0f48c33e42674",
  measurementId: "G-9VQ7TXVPV9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
setAnalyticsCollectionEnabled(analytics, true);
logEvent(analytics, 'notification_received');

// Route configuration
const AppRoutes = ({ darkMode, setDarkMode, setBotState }) => {
  return useRoutes([
    { path: '/', element: <Billbot darkMode={darkMode} setDarkMode={setDarkMode} setBotState={setBotState} /> },
    { path: '/demo', element: <Demo darkMode={darkMode} setDarkMode={setDarkMode} setBotState={setBotState} /> },
    { path: '/clean', element: <Clean setBotState={setBotState} /> },
    { path: '/adai', element: <AdAiDemo setBotState={setBotState} /> }
  ]);
};

function App() {
  const [botState, setBotState] = useState('none');
  const [darkMode, setDarkMode] = useState(false);
  const [currentGifSrc, setCurrentGifSrc] = useState(defaultGif);

  const getGifSource = (state) => {
    switch (state) {
      case 'search':
        return statusGif;
      case 'success':
        return statusGif;
      case 'error':
        return statusGif;
      case 'none':
      default:
        return defaultGif;
    }
  };
  
  const getStatusGif = (gifStatus) => { // Renamed 'status' to 'gifStatus'
    switch (gifStatus) {
      case 'search':
        return searchGif;
      case 'success':
        return successGif;
      case 'error':
        return errorGif;
    }
  };

  useEffect(() => {
    const nextGifSrc = getGifSource(botState);
    setCurrentGifSrc(nextGifSrc);
  }, [botState]);

  return (
    <Router>
      {/* <div className='bg'></div> */}
      <div className="media-container ">


          <video className="background-video" autoPlay muted loop src={video}>
            Your browser does not support the video tag.
            {/* <div class="background-gif">
            <img class="gif" src={currentGifSrc} alt="Background animation" />
            {botState !== 'none' && (
              <img
                src={getStatusGif(botState)}
                className={botState === 'success' ? 'status' : 'search status'}
                alt="Status animation"
              />
            )}
          </div> */}
          </video>
          <video className="background-video" autoPlay muted loop src={video}>
            Your browser does not support the video tag.
            {/* <div class="background-gif">
            <img class="gif" src={currentGifSrc} alt="Background animation" />
            {botState !== 'none' && (
              <img
                src={getStatusGif(botState)}
                className={botState === 'success' ? 'status' : 'search status'}
                alt="Status animation"
              />
            )}
          </div> */}
          </video>


  
      </div>
      <div className={`App ${darkMode ? 'dark' : ''}`}>
        

        <AppRoutes darkMode={darkMode} setDarkMode={setDarkMode} setBotState={setBotState} />
      </div>
    </Router>
  );
}

export default App;
