import React, { useState, useRef, useEffect } from 'react';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { app } from '../../firebase';
import './Demo.css';
import logo from '../../asset/logo-dot-white@4x.png';
import send from '../../asset/Send.png';
import mode from '../../asset/day-and-night (1).png';
import sound from '../../asset/sound.png';
import darkSound from '../../asset/dark-sound.png';
import bilbotgif from '../../asset/Virtual Assistants.gif'
import video from '../../asset/BILLBOT WEB enhanced.mp4'
import video0 from '../../asset/question0.mp4'
import video1 from '../../asset/question1.mp4'
import video2 from '../../asset/question2.mp4'
import video3 from '../../asset/question3.mp4'
import video4 from '../../asset/question4.mp4'
import video5 from '../../asset/question5.mp4'
import video6 from '../../asset/question6.mp4'
import video7 from '../../asset/question7.mp4'
import video8 from '../../asset/question8.mp4'
import video9 from '../../asset/question9.mp4'
import video10 from '../../asset/question10.mp4'
import audio1 from '../../asset/audio0.mp3';
import audio2 from '../../asset/audio1.mp3';
import audio3 from '../../asset/audio2.mp3';
import audio4 from '../../asset/audio3.mp3';
import audio5 from '../../asset/audio4.mp3';
import audio6 from '../../asset/audio5.mp3';
import audio7 from '../../asset/audio6.mp3';
import audio8 from '../../asset/audio7.mp3';
import audio9 from '../../asset/audio8.mp3';
import audio10 from '../../asset/audio9.mp3';
const db = getFirestore(app);

const AdAiDemo = ({ darkMode, setDarkMode }) => {
  // State variables
  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const [lang, setLang] = useState('EN');
  const [ttsEnabled, setTTSEnabled] = useState(false);
  const conversationRef = useRef(null);
  const [allowTagClick, setAllowTagClick] = useState(true);
  const [currentAnswerIndex, setCurrentAnswerIndex] = useState(0); // Track the index of the current answer
  const [typingIntervalId, setTypingIntervalId] = useState(null);
  const [timeStamp, setTimeStamp] = useState(null);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [showLink, setShowLink] = useState(false);

  const videoRef = useRef(null);
  const audioRef = useRef(null);
  
  const questionAnswerData = [
    {
      question: "Interviewer: Hi Billbot, thank you for joining us today. Can you start by telling us what you are and what you do?",
      answer: "Of course. Oh, and between you and me, I’ve got a spicy update on the way! Soon I’ll have access to even more comb data, so I’ll be able to provide even deeper, data-driven insights for all your OOH inquiries. So, stay tuned!"
    },
    {
      question: "Interviewer: Hi Billbot, thank you for joining us today. Can you start by telling us what you are and what you do?",
      answer: "How about a digital billboard that tracks how many cups of coffee have been sold that day and displays the number in real-time? You could also add a 'caffeinated meter' that fills up as more cups are sold. Once the meter hits a milestone—like 100 cups—the first 10 people to submit a screenshot of the filled meter get a free coffee! It’s a fun, interactive way to build excitement and reward your most engaged customers!"
    },
    {
      question: "Interviewer: How is AI being used in OOH advertising today?",
      answer: "A gym? Perfect! How about a billboard that challenges people to beat a ‘virtual trainer’ in a quick fitness game? Imagine a giant screen that shows a trainer doing jumping jacks or push-ups, and passersby can jump in and match the moves. Or, a billboard that changes to show motivating fitness quotes every time someone walks by, like ‘Your future abs thank you!’—and if they stop for too long, it playfully says, ‘Come on, don’t just stand there, those biceps won’t build themselves! Or... is standing your new workout?’ Guaranteed to give them a laugh and maybe a little guilt trip!"
    },
    {
      question: "Interviewer: Let’s dive deeper into these applications. How does AI assist in creative generation for OOH advertising?",
      answer: "Absolutely! E-commerce has had a huge impact on OOH advertising, especially with the rise of BORIS—Buy Online, Return In-Store. One shocking stat from our most recent August report is that 1 in 3 items purchased online are being returned in-store. That's a massive shift in consumer behavior! What makes this interesting for OOH is that this 'road to return' creates a prime opportunity for advertisers. Since 85% of people making BORIS returns end up purchasing something else while they're in the store, it's a perfect time to target them with strategic OOH ads. Placing ads along the route to return locations or near drop-off points allows brands to influence the next purchase while customers are already in that shopping mindset. I know I’m rambling a bit—it’s a lot to take in! But you’re welcome to download the report for future reference and, honestly, a good read. You’ll thank me later! August Insight Report"
    },
    {
      question: "Interviewer: Can you explain dynamic creative optimization and how it differs from regular creative optimization?",
      answer: "Of course. Oh, and between you and me, I’ve got a spicy update on the way! Soon I’ll have access to even more COMMB data, so I’ll be able to provide even deeper, data-driven insights for all your OOH inquiries. So, stay tuned!"
    },
    {
      question: "Interviewer: Targeting and audience profiling are crucial for effective advertising. How does AI enhance these processes in OOH?",
      answer: "Remember, I’m always here to guide you through the world of OOH advertising. Whether you need creative ideas, insight into industry trends, or access to detailed reports, I’m just a question away. And hey, don't forget to enjoy the rest of the AdAi conference—there’s so much inspiration and innovation here. I’ll be around if you need me. Have fun and let’s create something amazing!"
    }
  ];

  const sendMessage = async () => {
    const currentQA = questionAnswerData[currentAnswerIndex];
  
    if (currentAnswerIndex < questionAnswerData.length) {
      const userMessageData = {
        msg_text: userMessage,
        type: 'user'
      };
      setMessages(prevMessages => [...prevMessages, userMessageData]);
  
      const botMessageData = {
        msg_text: '',
        type: 'bot',
        timestamp: serverTimestamp(),
      };
      setMessages(prevMessages => [...prevMessages, botMessageData]);
      // setCurrentVideoIndex(prevIndex => prevIndex + 1);
      setCurrentAnswerIndex(prevIndex => prevIndex + 1);
      // videoRef.current.src = videoList[currentVideoIndex];
      // videoRef.current.play();
      setUserMessage('');
  
      // Play the corresponding audio
      // if (audioRef.current) {
      //   audioRef.current.src = audioList[currentAudioIndex];
      //   audioRef.current.play();
      //   setCurrentAudioIndex(prevIndex => prevIndex + 1);
      // }
  
      let index = 0;
      const typingInterval = setInterval(() => {
        if (index < currentQA.answer.length) {
          const botMessageWithTyping = currentQA.answer.substring(0, index + 1);
          const updatedBotMessageData = {
            ...botMessageData,
            msg_text: botMessageWithTyping
          };
          setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            updatedMessages[prevMessages.length - 1] = updatedBotMessageData;
            return updatedMessages;
          });
          index++;
        } else {
          clearInterval(typingInterval);
          setAllowTagClick(true);
          setTypingIntervalId(null);
        }
      }, 25);
      setTypingIntervalId(typingInterval);
  
      if (ttsEnabled) {
        handleTTS(currentQA.answer, currentAnswerIndex); 
      }
    } else {
      console.log('No more question-answer pairs to send.');
    }
  };
  


  const handleKeyDown = (e) => {
    if (allowTagClick && userMessage && e.key === 'Enter') {
      sendMessage();
    }
  };

  const scrollToBottom = () => {
    if (conversationRef.current) {
      conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
    }
  };

  const toggleTTS = () => {
    setTTSEnabled(!ttsEnabled);
  };

  const handleTTS = async (text, index) => {
    console.log(index);
    
    if (!ttsEnabled) return;
    text = text.replace(/COMMB/g, 'comb');
    text = text.replace(/OOH/g, 'O O H');
    try {
      const response = await fetch('https://ooh-gpt-2-0-tts-openai.onrender.com/synthesize', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate speech');
      }

      const audioBlob = await response.blob();
      console.log(audioBlob);
      const audioUrl = URL.createObjectURL(audioBlob);
      console.log(audioUrl);
      const newAudio = new Audio(audioUrl);
      console.log(newAudio);
      const downloadLink = document.createElement('a');
      downloadLink.href = audioUrl;
      downloadLink.download = `audio${index}.mp3`;
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setCurrentAudio(false);
      if (currentAudio) {
        currentAudio.pause();
      }
      setCurrentAudio(newAudio);
      newAudio.play();
      newAudio.addEventListener('ended', () => {
        setCurrentAudio(null);
      });
    } catch (error) {
      console.error('Error:', error.message);
    }
  };


  useEffect(() => {
    return () => {
      clearInterval(typingIntervalId);
    };
  }, [typingIntervalId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Effect to set timestamp
  useEffect(() => {
    const now = new Date();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const day = days[now.getDay()];
    const hours = now.getHours() % 12 || 12; 
    const minutes = now.getMinutes();
    const amPm = now.getHours() >= 12 ? 'PM' : 'AM';
    const formattedTimestamp = `${day} ${hours}:${minutes < 10 ? '0' + minutes : minutes} ${amPm}`;
    setTimeStamp(formattedTimestamp);
  }, []);

  return (
    <div className='demo chatbot'>
      {/* <div className="billbot">
        <img src={bilbotgif} alt="" />
      </div> */}
      <div className={`chatbox ${darkMode ? 'dark' : ''}`}>
        <div className="header">
          <img src={logo} alt="" />
          <div className="toggle-lang">
            <span className={lang === 'EN' ? 'selected' : ''} onClick={() => setLang('EN')}>EN</span>
            <span>|</span>
            <span className={lang === 'FR' ? 'selected' : ''} onClick={() => setLang('FR')}>FR</span>
          </div>
        </div>
        <div className='fixed'>
          <div className="timestamp">
            {timeStamp}
          </div>
          <div className="toggle-tts">
            <img src={darkMode ? darkSound : sound} alt="" />
            <div>            
              <input checked={ttsEnabled} onChange={toggleTTS} type="checkbox" id='tts' onClick={(e) => e.stopPropagation()}/>
              <label htmlFor="tts" className='button'></label>
            </div>
          </div>
        </div>
        <div className="conversation" ref={conversationRef}>
          <div className='bot'>
            {lang === 'EN' ? 'Hello, my name is BillBot, and I can answer questions about OOH. How can I help you?' :"Bonjour, je m'appelle BillBot, et je peux répondre aux questions sur les affichages extérieurs. Comment puis-je vous aider?"}
          </div>
          {messages.map((msg, index) => (
            <div key={index} className={msg.type}>
              <p>
                {msg.msg_text.includes('August Insight Report') ? (
                  <>
                    {msg.msg_text.split('August Insight Report')[0]}
                    <br />
                    <br />
                    <a
                      className={`pdf-link ${darkMode ? 'dark-mode' : ''}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      href='https://assets.ctfassets.net/1l6si2vnlb2k/3VIYFbEhQT2JE5Njw6gfHE/90a96287f4027289a0c09fa094674838/Insights_Report__August__ENG__5_.pdf'
                    >
                      August Insight Report
                    </a>
                    {msg.msg_text.split('August Insight Report')[1]}
                  </>
                ) : (
                  msg.msg_text
                )}
              </p>
            </div>
          ))}


        </div>
        <div className="user-input">
          <img src={mode} onClick={() => setDarkMode(!darkMode)}  alt=''/>
          <input
            placeholder={lang === 'EN'? 'Type your message here...' : 'Tapez votre message ici..'}
            value={userMessage}
            onChange={e => setUserMessage(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button onClick={sendMessage} className='send-button'><img src={send} alt="" /></button>
        </div>
      </div>  
      <div className="video-container">
        <video className="background-video" src={video} autoPlay muted loop type="video/mp4"></video>
        <audio ref={audioRef} autoPlay/>
      </div>  
    </div>
  );
}

export default AdAiDemo;
